import commonApi from "@/api/common";

export default {
    get: async () => {

        const jwt = sessionStorage.getItem("sgk-sso-jwt");
        const claimString = jwt.split(".")[1];
        const claim = JSON.parse(atob(claimString));
        const id = claim.sub;

        const method = 'GET';
        const url = `${process.env.VUE_APP_API_END_POINT}/employee/${id}`;

        const response = await commonApi.request(url, method);
        return response.json();

    }

}