<style lang="scss">
$headerHeight: 50px;
$padding: 20px;

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  overflow: auto;

  main {
    position: absolute;
    z-index: 1;
    width: calc( 100% - $padding * 2 );
    height: calc( 100% - $padding - $headerHeight );
    left: $padding;
    top: $headerHeight;
  }
}
</style>

<script setup>
import {ref} from "vue";
import FileDropZone from './components/FileDropZone/FileDropZoneWeb.vue'
import FileDropZoneMobile from "@/components/FileDropZone/FileDropZoneMobile.vue";
import Util from '@/modules/util';
import TheHeader from "@/components/TheHeader.vue";
import Jwt from "@/modules/Jwt";

const jwt = ref(Jwt.get());
</script>
<template>
  <div v-if="jwt">
    <the-header :height="50" style="width: calc( 100% - 50px ); left: 30px; z-index: 2;"></the-header>
    <main>
      <file-drop-zone-mobile v-if="Util.isMobile()"></file-drop-zone-mobile>
      <file-drop-zone v-else></file-drop-zone>
    </main>
  </div>
</template>
