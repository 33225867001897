<style scoped>
</style>

<script setup>
import { ref } from 'vue';
import FileDropZone from '@/components/FileDropZone/FileDropZone.vue';

let controller = null;
const active = ref(false);

const methods = {

  onMountedFileDropZone: (_controller) => {
    controller = _controller;
  },

  onClick: () => {

    controller.openFileBrowser();

  },

  onMouseenter: () => {

    if(active.value) {
      return;
    }
    active.value = true;

  },

  onMouseleave: () => {

    active.value = false;

  },

  onDragenter: () => {

    active.value = true;

  },

  onDragleave: () => {

    active.value = false;

  }

};

</script>

<template>
  <file-drop-zone
      :text="'클릭하거나 파일을 드래그 앤 드롭하세요.'"
      :active="active"
      @mounted="methods.onMountedFileDropZone"
      @click="methods.onClick"
      @mouseenter="methods.onMouseenter"
      @mouseleave="methods.onMouseleave"
      @dragenter="methods.onDragenter"
      @dragleave="methods.onDragleave"
      @drop.prevent
      @dragover.prevent
  ></file-drop-zone>
</template>