<style scoped>
</style>

<script setup>
import { ref } from 'vue';
import FileDropZone from '@/components/FileDropZone/FileDropZone.vue';

const active = ref(false);
let controller = null;

const methods = {

  onMountedFileDropZone: (_controller) => {
    controller = _controller;
  },

  onClick: () => {

    active.value = true;
    controller.openFileBrowser();


  },

  onCancelFileBrowse: () => {

    active.value = false;

  },

  onChangeStatus: ( status ) => {

    if( status === 'converting' ){
      return;
    }

    active.value = false;

  }

};
</script>

<template>
  <file-drop-zone
      :text="'클릭하세요.'"
      :active="active"
      @mounted="methods.onMountedFileDropZone"
      @click="methods.onClick"
      @cancelFileBrowse="methods.onCancelFileBrowse"
      @changeStatus="methods.onChangeStatus"
  ></file-drop-zone>
</template>