const Util = {
    isMobile() {
        const mobileRegex = [
            /Android/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i
        ]

        return mobileRegex.some(mobile => window.navigator.userAgent.match(mobile));
    },

    stringToImage: (() => {

        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        return ( string, size, fontSize, fontColor, backgroundColor ) => {

            context.save();
            canvas.width = size;
            canvas.height = size;
            canvas.style.cssText = `width: ${canvas.width}px; height: ${canvas.height}px`;
            context.clearRect(0, 0, size, size);

            context.fillStyle = backgroundColor;
            context.fillRect(0, 0, size, size);

            const halfSize = size / 2;
            context.translate(halfSize, halfSize);
            context.font = `${fontSize}px sans-serif`;
            context.fillStyle = fontColor;
            context.textBaseline = 'middle';
            context.textAlign = 'center';
            context.fillText(string[0], 0, 0);
            context.restore();

            return canvas.toDataURL();

        };

    })()
};

export {Util};
export default Util;