export default {
    request: (url, method, body) => {
        return fetch(`${url}`, {
            method,
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("sgk-sso-jwt")}`,
                'X-API-Key': process.env.VUE_APP_API_KEY,
            },
            body
        });
    }
}