import commonApi from '@/api/common';
export default {
    convert: async (fileId) => {

        const method = 'POST';
        const url = `${process.env.VUE_APP_API_END_POINT}/cad/convert/${fileId}`;

        const response = await commonApi.request(url, method);
        return response.json();

    }
}