import commonApi from "@/api/common";

export default {
    download: async (fileId) => {

        const method = 'GET';
        const url = `${process.env.VUE_APP_API_END_POINT}/file/download/${fileId}`;

        const response = await commonApi.request(url, method);
        return response.blob();

    },

    post: async (file) => {

        const method = 'POST';
        const url = `${process.env.VUE_APP_API_END_POINT}/file`;

        const body  = new FormData();
        body.append("file", file);

        const response = await commonApi.request(url, method, body);
        return response.json();

    }
}