<style lang="scss" scoped>
header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  width: 100%;

  .title {
    font-weight: bold;
    width: calc( 100% - 50px );
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .profile-wrap {
    position: relative;
    cursor: pointer;
    transition: all 500ms;
    &:hover {
      background-color: #eee;
    }

    .profile {
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
      background-color: #ccc;
    }
  }

  .profile-menu {
    position: fixed;
    right: 28px;
    top: 46px;
    width: calc( 100% - 48px );
    max-width: 240px;
    opacity: 0;
    pointer-events: none;
    transition: all 250ms;

    &.show {
      opacity: 1;
      pointer-events: auto;
    }
  }

  @media (max-width: 300px) {
    .profile-menu {
      right: 0px;
      width: calc(100% - 20px);
      border-radius: 0px;
    }
  }
}
</style>

<script setup>
import {ref, computed} from "vue";
import employeeApi from '@/api/employee';
import Util from "@/modules/util";
import ProfileMenu from "@/components/ProfileMenu.vue";
const props = defineProps({
  height: {
    type: Number,
    required: true
  },
});

const user = ref({});
const visibleProfileMenu = ref(false);

const headerStyle = computed(() => {

  return {
    height: `${props.height}px`,
  };

});

const profileWrapStyle = computed(() => {

  return {
    width: `${props.height}px`,
    height: `${props.height}px`,
    'border-radius': `${props.height}px`
  };

});

const profileStyle = computed(() => {

  return {
    width: `${props.height - 20}px`,
    height: `${props.height - 20}px`,
    'border-radius': `${props.height}px`,
    'background-image': `url('${Util.stringToImage(user.value.empName || ' ', 50, 24, '#ffffff', '#3499fe')}')`,
  };

});

const userText = computed(() => {

  if( !user.value.empName ){
    return;
  }

  return `${user.value.empName} (${user.value.empOfficeName || '프로'}/${user.value.deptName || '-'})`;

});

employeeApi.get().then(( employeeResult ) => {
  if( employeeResult.result !== 'SUCCESS' ){
    return;
  }

  user.value = employeeResult.data;
});

const methods = {

  onClickProfile: () => {
    visibleProfileMenu.value = !visibleProfileMenu.value;
  },

  onClickBody: () => {
    visibleProfileMenu.value = false;
  }

};

document.body.addEventListener("click", methods.onClickBody);
</script>

<template>
  <header :style="headerStyle">
    <div class="title">SGK CAD Converter</div>
    <div class="profile-wrap" :style="profileWrapStyle" @click.stop="methods.onClickProfile">
      <div class="profile" :style="profileStyle"></div>
    </div>
<!--    <profile-menu class="profile-menu" :class="{show: visibleProfileMenu}" :user-text="userText" @click.stop></profile-menu>-->
    <profile-menu class="profile-menu" :class="{show: visibleProfileMenu}" :user-text="userText" @click.stop></profile-menu>
  </header>
</template>

