<style scoped>
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  border: #ccc dashed 2px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  transition: all 500ms;
  width: calc( 100% - 4px );
  height: calc( 100% - 4px );
  left: 50%;
  top: 50%;
  color: #616161;
  overflow: auto;
  transform: translate(-50%, -50%);
}

.container * {
  pointer-events: none;
}

.blue-color {
  color: #1c7cd4;
}

.container.active {
  border-color: #1c7cd4;
  color: black;
  background-color: rgba(28, 124, 212, 0.3);
}

.container.converting {
  pointer-events: none;
}

.lottie-file-drop-container {
  position: relative;
  transform: scaleY(0);
  width: 50%;
  max-width: 300px;
  min-width: 200px;
  min-height: 0px;
  max-height: 0px;
  opacity: 0;
  transition: all 500ms;
}

.lottie-file-drop-container.show {
  margin: 40px 0px 0px 0px;
  max-height: 174px;
  min-height: 118px;
  opacity: 1;
  transform: scaleY(1);
}

.lottie-spinner-container {
  position: relative;
  transform: scaleY(0);
  width: 50%;
  max-width: 100px;
  min-width: 50px;
  min-height: 0px;
  max-height: 0px;
  opacity: 0;
  transition: all 500ms;
}

.lottie-spinner-container.show {
  margin: 20px 0px;
  min-height: 50px;
  max-height: 100px;
  opacity: 1;
  transform: scaleY(1);
}

.title {
  font-weight: bold;
  font-size: 28px;
}

.text {
  height: 50px;
  width: 280px;
}

.toast {
  position: fixed;
  transform: translate(-50%, 0%);
  left: 50%;
  bottom: 0px;
  opacity: 0;
  border-radius: 4px;
  padding: 10px 40px;
  box-shadow: 1px 1px 10px 4px #cccccc;
  color: white;
  pointer-events: none;
  white-space: nowrap;
  overflow: hidden;
  transition: all 500ms ease-in-out;
}

.toast > pre {
  margin: 0;
}

.toast.show {
  bottom: 80px;
  opacity: 1;
}

.toast.error {
  background-color: #F44336;
  border: #D32F2F solid 1px;
}

.toast.complete {
  background-color: #2196F3;
  border: #1976D2 solid 1px;
}

@media (min-width: 480px) {

  .title {
    font-size: 32px;
  }

}
</style>

<script setup>
import { onMounted, ref, watch, computed } from 'vue';
import download from 'downloadjs';
import Lottie from 'lottie-web';
import lottieDataFileDrop from '@/assets/images/lotties/file-drop.json';
import lottieDataSpinner from '@/assets/images/lotties/spinner.json';
import cadApi from '@/api/cad';
import fileApi from '@/api/file';

const props = defineProps({
  text: {
    type: String,
    default: ""
  },
  active: {
    type: Boolean,
    default: false
  }
});

const emits = defineEmits(['mounted', 'cancelFileBrowse', 'changeStatus']);
const lottieFileDropContainer = ref();
const lottieSpinnerContainer = ref();

// wait -> upload -> convert -> complete | fail -> wait
const status = ref('wait');
const failText = ref('변환에 실패하였습니다.\n관리자에게 문의하세요.');
const inputFileExtension = ref(null);
const toastViewTime = 5000;

const input = (() => {

  const input = document.createElement('input');
  input.type = 'file';
  input.accept = '.dxf,.dwg';
  input.addEventListener('change', async () => {

    status.value = 'upload';
    const file = input.files[0];
    if(!file){
      return;
    }

    const extension = file.name.split(".").pop().toLowerCase();
    if( extension !== 'dxf' && extension !== 'dwg' ){
      status.value = 'fail';
      failText.value = '파일의 확장자는 dxf와 dwg만 가능합니다.';
      methods.toWaitStatus();
      return;

    }

    inputFileExtension.value = extension.toLowerCase();
    let uploadFileResult = null;

    // post
    try {
      uploadFileResult = await fileApi.post( file );
      if( uploadFileResult.result !== "SUCCESS" ){
        throw uploadFileResult.data;
      }

    } catch(error) {
      status.value = 'fail';
      failText.value = '업로드에 실패하였습니다.\n관리자에게 문의하세요.';
      console.error(error);
      methods.toWaitStatus();
      return;
    }

    status.value = 'convert';
    let convertFileResult = null;

    try {

      convertFileResult = await cadApi.convert(uploadFileResult.data.id);
      if( convertFileResult.result !== "SUCCESS" ){
        throw convertFileResult.data;
      }

    } catch(error) {

      status.value = 'fail';
      failText.value = '변환에 실패하였습니다.\n관리자에게 문의하세요.';
      console.error(error);
      return methods.toWaitStatus();

    }

    const blob = await fileApi.download(convertFileResult.data.id);
    status.value = 'complete';
    methods.toWaitStatus();
    download(blob, `${convertFileResult.data.name}.${convertFileResult.data.extension}`, `${convertFileResult.data.extension}/application`);

  });

  input.addEventListener('cancel', (event) => {
    emits('cancelFileBrowse', event);
  });
  return input;

})();

const lottie = {
  fileDrop: null,
  spinner: null
};

const methods = {
  toWaitStatus: () => {

    return new Promise((resolve) => {

      input.value = '';
      inputFileExtension.value = null;
      setTimeout(() => {
        status.value = 'wait';
        resolve();
      }, toastViewTime);

    });

  }
}

watch(() => props.active, ( value ) => {

  if(value){
    lottie.fileDrop.playSegments([lottie.fileDrop.currentFrame, 60],true);
    return;
  }

  lottie.fileDrop.playSegments([lottie.fileDrop.currentFrame, 0],true);
});

watch(() => status.value, ( value ) => {

  emits('changeStatus', value);

});

const text = computed(() => {

  if( status.value === 'upload' ){
    return '업로드중 입니다.';
  }

  if( status.value === 'convert' ){
    return '변환중 입니다.';
  }

  return props.text;

});

const arrowText = computed(() => {

  if( inputFileExtension.value === 'dxf' ){
    return ' <- ';
  }

  if( inputFileExtension.value === 'dwg' ){
    return ' -> ';
  }

  return ' <-> ';

});

const converting = computed(() => {

  return ['upload', 'convert'].some(( value ) => {
    return status.value === value;
  });

});

onMounted(() => {

  lottie.fileDrop = Lottie.loadAnimation({
    container: lottieFileDropContainer.value,
    loop: false,
    autoplay: false,
    animationData: lottieDataFileDrop
  });
  lottie.fileDrop.setSpeed(3);

  lottie.spinner = Lottie.loadAnimation({
    container: lottieSpinnerContainer.value,
    loop: true,
    autoplay: true,
    animationData: lottieDataSpinner
  });

  emits('mounted', {

    openFileBrowser: () => {
      input.click();
    }

  });

});

</script>

<template>
  <div class="container" :class="{active: props.active, converting}">
    <div class="title">
      SGK CAD Converter
    </div>
    <div class="lottie-file-drop-container" :class="{show: !converting}" ref="lottieFileDropContainer"></div>
    <div class="lottie-spinner-container" :class="{show: converting}" ref="lottieSpinnerContainer"></div>
    <div class="text">
      <strong>
        <span :class="{'blue-color': inputFileExtension === 'dxf'}">dwg</span>
        <span :class="{'blue-color': inputFileExtension !== null}">{{arrowText}}</span>
        <span :class="{'blue-color': inputFileExtension === 'dwg'}">dxf</span>
      </strong>
      <br>
      {{text}}
    </div>
    <div class="toast error" :class="{show: status === 'fail'}">
      <pre>{{ failText }}</pre>
    </div>
    <div class="toast complete" :class="{show: status === 'complete'}">
      변환이 완료되었습니다.
    </div>
  </div>
</template>